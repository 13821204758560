:root {
	--primary-bg: #242526;
	--secondary-bg: #fff;
	--primary-text-color: #555;
	--secondary-text-color: #cecece;
	--border-radius: 8px;
	--speed: 500ms;
  }
body {
	background-color: #F8F8F8;
}

* {
	font-family: poppins-light;
	letter-spacing: .6px;
}

@font-face {
	font-family: gothom;
	src: url('../font/gotham/GothamRounded-Medium.otf') format("opentype");
}

@font-face {
	font-family: saira-bold;
	src: url('../font/Saira-SemiBold.ttf') format("opentype");
}

@font-face {
	font-family: gothom-light;
	src: url('../font/gotham/GothamRounded-Light.otf') format("opentype");
}

@font-face {
	font-family: popines-medium;
	src: url('../font/poppins/Poppins-Medium.ttf') format("opentype");
}

@font-face {
	font-family: popines-semiblod;
	src: url('../font/poppins/Poppins-SemiBold.ttf') format("opentype");
}

@font-face {
	font-family: poppins-light;
	src: url('../font/poppins/Poppins-Light.ttf') format("opentype");
}

@font-face {
	font-family: poppins-bold;
	src: url('../font/poppins/Poppins-Bold.ttf') format("opentype");
}
.infoPic img{
	width: 100%;
	height: 165px;
	width: 200px;
	object-fit: cover;
	border-radius: 7px;
}
.homeLink a{
    font-size: 14px;
    color: #666262;
    letter-spacing: 1px;
	font-family: inherit;
}
.modal-body::-webkit-scrollbar-thumb {
	background-color: transparent !important;
}

.modal-body:hover::-webkit-scrollbar-thumb {
	background-color: #a1a6b7 !important;
}

.modal ::placeholder {
	font-size: 13px;
	color: #b2b2b2;
}

.fw-bold {
	font-family: popines-semiblod;
}

.boxShadow {
	box-shadow: 0px 0px 2px 0 rgb(0 0 0 / 5%), 0px 1px 4px 1px rgb(0 0 0 / 8%);
	overflow: hidden;
	border-radius: 8px;
}

.form-check {
	align-items: center;
	display: flex;
}

.form-check .form-check-input {
	height: 20px;
	padding: 0px;
	width: 20px;
}

.form-check label {
	padding-bottom: 0px !important;
	padding-left: 11px;
}

input[type="file"] {
	display: none;
}

.custom-file-upload {
	background-color: #ECECEC;
	display: inline-block;
	padding: 6px 12px;
	margin-bottom: 11px;
	border-radius: 5px;
	cursor: pointer;
	width: 100%;
	height: 120px;
	justify-content: center;
	align-items: center;
	display: flex;
	vertical-align: middle;
}

h5 {
	font-size: 15px;
	font-family: popines-medium;
	color: #7D7D7D;

}

.listStyle {
	list-style: none;
}

.w-70 {
	width: 68%;
}

.w-30 {
	width: 28%;
}

.defaultPadding {
	padding: 30px 0px;
}

.btn-primary {
	background-color: #004BFF;
	color: #fff;
}

.btn-close {
	opacity: 1;
}

.btn:focus {
	outline: 0;
	box-shadow: none;
}
.shadow-btn:focus {
	outline: 0;
	box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 12%), 0px 0px 0px 0.5px rgb(0 0 0 / 40%);
}
.logo img {
	width: 180px;
	cursor: pointer;
}

.modelHeading {
	color: rgb(0 0 0 / 100%);
	font-family: popines-semiblod;
	font-size: 17px;
}

.modal-content {
	border-radius: 15px;
	padding: 10px;
}

.modal-body {
	max-height: 460px;
	overflow: auto;
	margin: 0px;
	padding: 15px 20px !important;
}

.fade {
	transition: opacity .15s linear;
	background: rgb(0 0 0 / 72%);
}

.inputBox input {
	outline: none;
	height: 40px;
	border: 0px;
	padding: 5px 15px;
	border-radius: 5px;
	box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 48%), 0px 0px 0px 0.5px rgb(0 0 0 / 45%);
	color: black;
	font-size: 15px;
}

.inputBox select {
	box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 48%), 0px 0px 0px 0.5px rgb(0 0 0 / 45%);
	color: black;
	height: 100%;
	height: 40px;
	border: 0px;
	font-size: 15px;
}

.shadowBox {
	width: 100%;
	height: 100%;
	display: none;
	position: absolute;
	z-index: 1;
}

.modal-content {
	padding: 0px;
}

.modal-header,
.modal-footer {
	background-color: #efefef;
}

.inputBox label {
	color: #191919;
	font-size: 12px;
	padding-bottom: 10px;
	font-family: 'poppins-light';
}

.inputBox textarea {
	outline: none;
	padding: 10px 15px;
	box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 48%), 0px 0px 0px 0.5px rgb(0 0 0 / 45%);
	border-radius: 5px;
	color: black;
	height: 120px;
	width: 100%;
	border: 0px;
}

.searchBox {
	border: 1px solid #9F9F9F;
	border-radius: 10px;
	height: 47px;
}

.searchBox input {
	background-color: transparent;
	border: none;
	height: 100%;
	outline: none;
	color: #9F9F9F;
	font-size: 14px;
	width: 230px;
	padding-left: 10px;
}

.searchInput button {
	color: #9F9F9F;
}

.headerLinks img {
	width: 20px;
	height: 20px;
}

.headerLinks {
	background-color: #F4F4F4;
	position: relative;
	padding: 14px 17px;
	cursor: pointer;
	border-radius: 7px;
}

.headerLinks:hover {
	background-color: #e2dfdf;
}
.popupBox::before{
	content: "";
	position: absolute;
	transform: translate(-50%,-50%);
	top: 0%;
	left: 50%;
	width: 0; 
	height: 0; 
	border-left: 15px solid transparent;
	border-right: 15px solid transparent;
	border-bottom: 15px solid #1351f5;
}
.hidePopup .popupBox{
	display: none;
}
.popupBox{
	position: absolute;
	transform: translate(-50%,-50%);
	top: 200%;
	left: 50%;
	background: #fff;
	width: 215px;
	text-align: center;
	border-radius: 8px;
	font-size: 12px;
	z-index: 5;
	box-shadow: 0px 0px 13px 7px rgb(0 0 0 / 7%), 0px 3px 4px 2px rgb(0 0 0 / 3%);
}
.popupBox span{
	padding: 10px;
	color: #fff;
	width: 207px;
	line-height: 39px;
}
.popupBox div:nth-child(1){
	background-color: #1351f5;
}
.counter {
	font-weight: bold;
	position: absolute;
	background-color: #000;
	color: #fff;
	font-size: 10px;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	justify-content: center;
	vertical-align: middle;
	display: flex;
	align-items: center;
	top: 0px;
	right: 0px;
}

.profileBtn img {
	width: 30px;
	height: 30px;
	object-fit: cover;
	border-radius: 10px;
	cursor: pointer;
}

.profilName{
	cursor: pointer;
}

.profileBtn {
	position: relative;
	
}

.profileBtn span {
	position: absolute;
	right: -3px;
	bottom: 0px;
	width: 10px;
	border-radius: 50%;
	height: 10px;
	background-color: #b1b1b1;
	
}

.profileBtn .active {
	background-color: #31F300;
}

.mainDashboard {
	overflow: hidden;
	min-height: 80vh;
	background-color: #F8F8F8;
	padding-top: 80px;
}

.headingBar {
	text-align: center;
	padding: 14px;
}

.revenueCard {
	border-radius: 15px;
	position: relative;
	box-shadow: 0px 0px 2px 0 rgb(0 0 0 / 5%), 0px 1px 4px 1px rgb(0 0 0 / 8%);
}

.revenueDetails {
	color: #777777;
	background-color: #F8F8F8;
	padding: 15px 25px;
	border-radius: 25px;
}

.revenueCard::after {
	content: "";
	position: absolute;
	transform: translate(-50%, -50%);
	top: 1.5px;
	width: 90%;
	background-color: #1351F5;
	height: 4px;
}

.headingBar {
	border-bottom: .5px solid rgb(181 181 181);
}

.alldetailCard {
	display: flex;
	height: 130px;
	text-align: center;
}

.alldetailCard div:nth-child(1) {
	width: 35%;
	height: 90px;
	vertical-align: middle;
	justify-content: center;
	display: flex;
	align-items: center;
	border-radius: 8px 0px 0px 8px;
}

.alldetailCard img {
	width: 36px;
	height: 36px;
	display: flex;
	vertical-align: middle;
	align-items: center;
}

.alldetailCard div:nth-child(2) {
	width: 65%;
	height: 100%;
	vertical-align: middle;
	justify-content: center;
	border-radius: 7px;
	display: flex;
	align-items: center;
	position: relative;
	box-shadow: 0px 0px 2px 0 rgb(0 0 0 / 5%), 0px 1px 4px 1px rgb(0 0 0 / 8%);
}

.bar::after {
	content: "";
	top: 0px;
	border-radius: 25px;
	height: 4px;
	width: 90%;
	position: absolute;
}

.bar span {
	font-size: 11px;
}

.primaryFont {
	font-weight: bold;
	font-size: 25px !important;
}

.rounded-5 {
	border-radius: 7px;
	box-shadow: -1px 0px 8px 0 rgb(0 0 0 / 5%), 0 6px 20px 0 rgb(0 0 0 / 5%);
}

.profilePage h5 {
	color: #000 !important;
}

.small-font {
	font-size: 12px;
}

.medium-font{
	font-size: 13px;
}

.large-font{
	font-size: 15px!important;
	font-family: popines-medium;
}

.chartHeading {
	font-size: small;
}

.linechartHeading span:nth-child(1) {
	color: #000;
	font-size: 14px;
}

.linechartHeading span:nth-child(2) {
	color: rgb(195, 195, 195);
	font-size: 25px;
	font-family: poppins-bold;
}

.topBar::after {
	content: "";
	position: absolute;
	transform: translate(-50%, -50%);
	top: 1.5px;
	left: 50%;
	width: 95%;
	background-color: #1351F5;
	height: 4px;
}

.boxTitle {
	position: relative;
	width: 100%;
	border-radius: 8px 8px 0px 0px;
	border-bottom: .5px solid rgb(181 181 181);
}

.noticeDetails,
.appointment {
	border-radius: 0px 0px 8px 8px;
}

.noticeDetails span,
.noticeDetails p {
	font-size: 12px;
	color: #7D7D7D;
}

.profileCard img {
	width: 45px;
	height: 45px;
	border-radius: 8px;
	object-fit: cover;
}

.profileCard span:nth-child(1) {
	font-size: 11px;
	font-weight: bold;
}

.profileCard span:nth-child(2) {
	font-size: 9px;
	display: block;
}

.profileCard .btn {
	font-size: 10px;
	border-radius: 25px;
}

.profileCard {
	background-color: #F5F5F5;
	border-radius: 8px;
}

.barChart {
	font-weight: bold;
	font-size: 14px;
}

.barChartTag div {
	font-size: 13px;
}

.red,
.blue {
	background-color: #FF3255;
	width: 15px;
	height: 15px;
	border-radius: 3px;
}

.blue {
	background-color: #1351F5;
}

.pieChart {
	position: relative;
}

.pieChart .barChartTag {
	position: absolute;
	transform: translate(-50%, -50%);
	top: 50%;
	left: 50%;
}

.chartDates span {
	border-radius: 20px;
	text-align: center;
	font-size: 12px;
	background-color: #dfdcdc;
	color: #000;
	padding: 5px 15px;
}

.chartlinks {
	padding: 5px 10px;
	background-color: #ffff;
	box-shadow: -1px 0px 8px 0 rgb(0 0 0 / 5%), 0 6px 20px 0 rgb(0 0 0 / 5%);
	border-radius: 20px;
}

.chartlinks li {
	color: rgb(183, 182, 182);
	padding: 5px 15px;
	font-size: 12px;
}

.chartlinks .active {
	background-color: #1351F5;
	color: #fff;
	border-radius: 20px;
}

.cs-icon-Calendar {
	display: none !important;
}

.cmvMonthTableRows span {
	margin: 0px auto;
}

.cmvDayHeader {
	font-family: popines-semiblod;
	color: rgb(88, 88, 88);
}

.calendarCont td {
	padding: 37px 21px;
	text-align: center;
	width: 134px;
	vertical-align: middle;
}

.cmvMonthTableRowDayHeader td {
	color: #000;
	font-weight: bold;
	font-size: 15px;
}

.cTodayHighlightCircle {
	color: #000;
	padding: 8px;
}

.cmvCalendarContWithBorders td {
	background-color: #fff;
	color: #000;
}

.cmvDayNumber {
	display: flex;
	justify-content: center;
	vertical-align: middle;
	align-items: center;
	padding: 8px;
	background-color: #F8F8F8;
}

.cs-icon-Next:before {
	font-family: "Font Awesome 6 Free";
	font-weight: 900;
	content: "\f061";
	font-size: 18px;
	color: #bdbdbd;
}

.cContHeaderButton {
	width: auto;
	margin-left: 13px;
}

.cContHeaderMenuSections li {
	margin-left: 0px;
}

.cs-icon-Prev:before {
	font-family: "Font Awesome 6 Free";
	font-weight: 900;
	content: "\f060";
	font-size: 18px;
	color: #bdbdbd;
}

#cContHeaderMenuAgendaView {
	display: none;
}

.cContHeaderMenuSegmentedTab .cContHeaderMenuSections li {
	border: 0px;
	color: #CFCFCF;
}

.cContHeaderMenuSegmentedTab .cContHeaderMenuSections li.cSelectedMenu {
	background: #004BFF;
	color: #FFFFFF;
}

.cContHeaderMenuSections {
	background: #F0F0F0;
	padding: 6px 7px;
	border-radius: 20px;
}

.cContHeaderMenuSegmentedTab .cContHeaderMenuSections li {
	border-radius: 20px;
	margin-top: 0px;
	font-size: 14px;
	padding: 2px 10px;
}

.cContHeader {
	background-color: #fff;
}

.clickableLink {
	background-color: transparent;
}

.cContHeaderMenuSections li:hover {
	background-color: #fff;
	color: #000;
}

.calendarContWeb .cContHeaderMenuSegmentedTab .cContHeaderMenuSections li:hover {
	background: #fff;
	transition: .2s;
	color: #000;
}

.cContHeaderMenuSegmentedTab .cContHeaderMenuSections li {
	background: #f0f0f0;
}

.cs-icon-Next,
.cs-icon-Prev {
	height: auto;
	line-height: auto;
}

.cContHeader {
	height: 70px !important;
}

.cContHeaderSections {
	padding: 12px 8px;
}

.homeCalendar {
	height: 483px !important;
}
.detailCalendar .cContHeaderSections{
	padding: 0px!important;
}

.calenderBox {
	padding: 20px;
	overflow: hidden;
	background-color: #fff;
	border-radius: 7px;
	box-shadow: -1px 0px 8px 0 rgb(0 0 0 / 5%), 0 6px 20px 0 rgb(0 0 0 / 5%);
}

.calendarCont {
	border: 0px;
}

.cmvThinBorder {
	border: 0px;
}

.calenderHeading {
	font-size: 16px !important;
	padding: 2px 0px;
	color: #000 !important;
}

.cContHeaderSectionLeft {
	padding-top: 0px;
}

.btn-gray {
	border: 1px solid #B3B3B3;
	color: #B3B3B3;
}

.bg-gray{
	background-color: #F0F0F0;
}

.shadow-btn {
	box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 12%), 0px 0px 0px 0.5px rgb(0 0 0 / 40%);
	color: #999999;
	display: flex;
	justify-content: center;
	vertical-align: middle;
	align-items: center;
	border-radius: 3px;
	padding: 10px 12px;
}

.shadow-btn:hover {
	color: #999999;
	background-color: #f5f5f5;
}

.shadow-btn img {
	width: 16px;
}

.tableNav .navbar-nav {
	border: 1px solid rgb(149 149 149 / 56%);
	border-radius: 6px;
	overflow: hidden;
}

.tableNav ul li a,.tableNav ul li .btn {
	padding: 12px !important;
	font-size: 11px;
	background-color: #fff;
	border-right: 1px solid rgb(149 149 149 / 56%);
	font-family: popines-semiblod;
	color: #1351f5;
}

.tableNav ul li:nth-last-child(1) a {
	border-right: none;
}

.tableHeading {
	font-size: 17px;
	color: #000;
	font-family: popines-semiblod;
}

.gray-text {
	color: #818181;
}

.findBox {
	box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 12%), 0px 0px 0px 0.5px rgb(0 0 0 / 40%);
	border-radius: 5px;
}

.findBox {
	width: 215px;
}

.findBox input {
	background-color: transparent;
	font-size: 12px;
	width: 62%;
	border: 0px;
	outline: none;
	padding: 10px 0px;
	color: #434343;
}

.findBox .btn {
	color: #ababab;
	padding: 8px 12px;
}

.shadow-btn {
	position: relative;
}

.shadow-btn span {
	font-size: 11px;
	letter-spacing: .7px;
	position: absolute;
	top: 62px;
	background: #000;
	padding: 6px 11px;
	color: #fff;
	display: none;
	z-index: 3;
	left: 50%;
	width: auto;
	border-radius: 5px;
	transform: translate(-50%, -50%);
	white-space: nowrap;
}

.shadow-btn span::before {
	position: absolute;
	content: "";
	background-image: url(../img/dropdown.png);
	background-size: cover;
	width: 20px;
	height: 20px;
	z-index: 3;
	top: -2px;
	transform: translate(-50%, -50%);
	left: 50%;
	background-position: center;
}

.shadow-btn:hover span {
	display: block;
}


.table tbody tr th,
.table thead tr th:nth-child(1) {
	padding-left: 30px;
	text-align: center;
}

.secondTable tbody tr th,
.secondTable thead tr th:nth-child(1) {
	padding-left: 110px;
	text-align: left;
}

.table thead th {
	border: 1px solid #cfcfcf;
    border-bottom: 1px solid #E3E3E3 !important;
    padding: 12px 25px;
    font-family: popines-medium;
    color: #000;
    font-size: 12px;
    background-color: #e8e8e8;
}
.smallTableFont .table thead th,.smallTableFont .table tbody td{
    padding: 12px 8px;
    font-size: 10px;
}

.table tbody th,
.table tbody td {
	padding: 15px 28px;
	vertical-align: middle;
	color: #050505;
    font-size: 12px;
    letter-spacing: 0.4px;
}

.table tbody tr {
	background-color: #fff;
}

.table thead th:nth-child(1) {
	border-left: 0px;
}

.table thead th:nth-last-child(1) {
	border-right: 0px;
}

.dateInput input {
	width: 111px;
	border: 0px;
	box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 12%), 0px 0px 0px 0.5px rgb(0 0 0 / 40%);
	border-radius: 5px;
	outline: none;
	height: 100%;
	padding: 0px 8px;
	cursor: pointer;
	font-size: 10px;
}

.dateInput input::placeholder {
	padding: 0px 7px;
}

.pageIcon {
	background-color: #A094ED;
	border-radius: 5px;
	width: 68px;
	height: 68px;

}

.pageIcon img {
	width: 33px;
}

.item-center {
	display: flex;
	justify-content: center;
	vertical-align: middle;
	align-items: center;
}

.iconBtn {
	width: 30px;
	height: 30px;
}

.iconBtn img {
	width: 15px;
	height: 15px;
}

.dropdown-menu {
	inset: initial !important;
	transform: initial !important;
	left: 0px !important;
	top: 35px !important;
	min-width: 20rem;
}

.dropdown-item {
	font-size: 12px;
	padding: 10px 30px;
	position: relative;
	color: rgb(0 0 0 / 75%);
}

.categorydrop {
	position: initial;
}

.dropdown-item.active {
	background-color: #F8F8F8;
	color: rgb(0 0 0 / 100%);
	font-family: popines-medium;
}

.dropdown-item.active::before {
	width: 15px;
	content: "";
	background-image: url(../img/tick.svg);
	position: absolute;
	height: 15px;
	background-size: cover;
	background-position: center;
	left: 20px;
	top: 12px;
}

.dropdown-menu h5 {
	padding: 10px 30px;
	font-size: 12px;
	font-family: popines-semiblod;
	color: rgb(0 0 0 / 75%);
}

.card-name {
	height: 100%;
	background-color: #F8F8F8;
	width: 170px;
	border-radius: 11px 0px 0px 11px;
}

.card-name .label-title {
	display: block;
	padding-bottom: 10px;
	color: #078195;
	font-size: 13px;
	text-align: center;
	line-height: 28px;
	font-weight: bold;
	margin-bottom: 30px;
}

.card-name .input-wrapper {
	position: relative;
	height: 100%;
	align-items: center;
	display: flex;
	padding: 0px;
	padding-left: 20px;
}

.card-name .card-input {
	width: 100%;
	outline: none;
	padding: 4px 0px;
	font-size: 12px;
	background: transparent;
	cursor: pointer;
	position: relative;
	color: rgb(0 0 0 / 49%);
	font-family: popines-semiblod;
	border-radius: 3px;
}

.card-name .arrow {
	position: absolute;
	top: 10px;
	right: 14px;
	cursor: pointer;
	color: #9da1a3;
}

.card-name ul li {
	padding: 11px 16px;
	color: #000000;
}

.card-list {
	z-index: 20;
}

.card-name img {
	padding: 15px;
	width: 47px;
	height: 47px;
	border-radius: 5px;
}

.card-name ul.card-list {
	max-height: 700px;
	font-size: 14px;
	overflow: auto;
	list-style: none;
	padding: 0;
	margin: 0;
	position: absolute;
	width: 100%;
	display: none;
	background: #ffffff;
	box-shadow: -1px 0px 8px 0 rgb(0 0 0 / 5%), 0 6px 20px 0 rgb(0 0 0 / 5%);
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	top: 56px;
	left: 0px;
	border: 1px solid #d5d5d5;
	width: 300px;
}


.sub-menu-wrap{
   position: absolute;
   top: 100%;
   right: 10%;
   width: 320px;
   max-height: 0px;
   overflow: hidden;
   transition: max-height 0.5s;
}
.sub-menu-wrap.open-menu{
	max-height: 400px;
}
 
.sub-menu {
	background: #fefefe;
	padding: 20px;
	margin: 10px;
}

.user-info{
	display: flex;
	align-items: center;
}
.user-info h3 {
	font-weight: 500;
}
.user-info img {
	width: 60px;
	border-radius: 20%;
	margin-right: 15px;
}

.sub-menu hr {
	border: 0;
	height: 1px;
	width: 100%;
	background: #ccc;
    margin: 15px 0 10px;
}
.sub-menu-link{
 display: flex;
 align-items: center;
 text-decoration: none;
 color: #525252;
 margin: 12px 0;
}
.sub-menu-link p{
	width: 100%;
}
.sub-menu-link img{
	width: 40px;
	background: #e5e5e5;
	border-radius: 50%;
	padding: 8px;
	margin-right: 15px;
}
.sub-menu-link span{
	font-size: 22px;
}
.sub-menu-link:hover p{
	font-weight: 600;
}

.card-list {
	border-radius: 5px;
}

 
.card-name ul.card-list.visible {
	display: block;
}

.card-name ul li {
	cursor: pointer;
}

.card-name ul li {
	border-top: 0;
}

.card-name ul li .fab,
.card-input .fab {
	font-size: 32px;
}

.card-input img {
	display: none;
}

.card-input {
	font-size: 13px;
}

.centerTable thead tr th,
.centerTable td {
	text-align: center !important;
	border-left: 0px;
	border-right: 0px;
}

.btn-success {
	background-color: #2CDD00;
}

.btn-danger {
	background-color: #D40000;
}

.profileImg img{
	width: 145px;
	height: 145px;
	object-fit: cover;
	border-radius: 50%;
	border:3px solid #fff
}

.profileIcons span{
	font-size: 13px;
}

.profileIcons span svg{
	font-size: 17px!important;
}

.boxInfo{
	border-radius: 8px;
}

.boxInfo div span{
	color: rgb(103, 103, 103);
}

.tabsBox .nav-tabs .nav-link {
	font-size: 11px;
	border: 1px solid #dfdfdf;
	border-right: .5px;
	border-radius: 0px;
	padding: 10px 15px;
}
.tabsBox li .active{
	color: #004BFF!important;
}
.tabsBox .nav-tabs .nav-item:nth-last-child(1) .nav-link{
	border-right: 1px solid #dfdfdf;
	border-radius: 0px 5px 5px 0px;
}
.tabsBox .nav-tabs .nav-item:nth-child(1) .nav-link{
	border-radius: 5px 0px 0px 5px;
}
.tabsBox .nav-tabs .nav-link.active{
	border: 1px solid #dfdfdf;
}

.invoiceTop img{
	width: 140px;
}

.invoiceBox thead tr th{
	font-size: 12px;
}
.invoiceBox tbody tr td,.invoiceBox tbody tr th{
	font-size: 11px;
}

.invoiceBox .table tbody th,.invoiceBox .table tbody td ,.invoiceBox .table thead th{
    padding: 10px 15px;
}

.prescriptionBox{
	margin: 10px;
	background: #fff;
	box-shadow: 0px 0px 2px 0 rgb(0 0 0 / 5%), 0px 1px 4px 1px rgb(0 0 0 / 8%);
}
.prescriptionUpper img{
	width: 140px;
}

.boxHeight{
	height: 33px;
	overflow: hidden;
}
.prescriptionDetail{
	height: 720px;
}

.timelineBox{
    padding: 25px 63px;
    background: #f0f0f0;
    border-radius: 106px;
}
.cardBox{
    background: #f1f1f1;
	box-shadow: 0px 0px 2px 0 rgb(0 0 0 / 5%), 0px 1px 4px 1px rgb(0 0 0 / 8%);
    border-radius: 13px;
    padding: 17px;
}
.barcodeImage img{
	width: 170px;
	margin-left: 15px;
	object-fit: cover;
}
.inputBox .amsify-selection-label{
	height: 34px;
}
.inputBox .amsify-selection-label .amsify-label{
	font-size: 12px;
}
.amsify-selection-area .amsify-selection-label-default{
	padding: 4px 8px;
}
.amsify-selection-area .amsify-selection-label .amsify-label {
	line-height: 24px;
}
.amsify-select-operations{
	display: none;
}
.amsify-selection-area .amsify-selection-list ul.amsify-list li.active {
    background: #1351f5;
    font-weight: bold;
    color: #fff;
    font-size: 12px;
    padding: 8px;
}
.amsify-selection-area .amsify-selection-list ul.amsify-list li.amsify-list-item {
    text-align: left;
    cursor: pointer;
    padding: 3px 10px;
	font-size: 12px;
}
.amsify-list{
	margin-bottom: 0px;
}
.amsify-selection-area .amsify-selection-list .amsify-select-search-area .amsify-selection-search {
    width: 98%;
    border-radius: 25px;
    padding: 3px 7px;
    border: 1px solid #ffffff;
    font-size: 12px;
    padding-left: 15px;
    margin: 5px 0px;
}
.amsify-selection-area .amsify-selection-list ul.amsify-list li.amsify-item-noresult {
    display: none;
    color: #ff6060;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    padding: 5px 0px;
}
.amsify-selection-area .amsify-selection-list ul.amsify-list li.amsify-list-item {
    text-align: left;
    cursor: pointer;
    padding: 9px 10px;
    font-size: 12px;
}
.amsify-selection-area .amsify-selection-list ul.amsify-list li.amsify-list-item:hover {
    background: #eaeaea;
}
.amsify-toggle-selection {
    float: right;
    color: #afafaf;
    cursor: pointer;
}
.amsify-selection-list{
	min-width: 191px;
}
.largeSearch .amsify-selection-list {
    min-width: 322px!important;
}
.profileUpload img{
	width: 130px;
    height: 130px;
    border-radius: 50%;
}
@media only screen and (min-width: 576px) {
	.modal-dialog {
		max-width: 724px;
		margin: 1.75rem auto;
	}
}

/*login*/

.login-background .nice-select {
	width: 100%;
	height: 50px;
	padding-top: 4px;
	border-color: #ccccccfc;
}

.login-background ul.list {
	width: 100%;
	-webkit-box-shadow: 9px 12px 13px -4px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 9px 12px 13px -4px rgba(0, 0, 0, 0.2);
	box-shadow: 0px 0px 13px 0px rgb(0 0 0 / 20%);
}

.login-background .form-group {
	margin-bottom: 25px;
}

.login-background input#inlineCheckbox1 {
	width: 30px;
	padding: 0;
	margin: 1px;
}

.radio-btn {
	width: 25px !important;
	cursor: pointer;
}

.login-background .login .checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"],
.radio input[type="radio"],
.radio-inline input[type="radio"] {
	position: absolute;
	margin-left: -23px;
	top: 6px;
}

.login-background .login .checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"],
.radio input[type="radio"],
.radio-inline input[type="radio"] {
	position: absolute;
	margin-left: -23px;
	top: 6px;
}

.login-background .login .checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"],
.radio input[type="radio"],
.radio-inline input[type="radio"] {
	position: absolute;
	margin-left: -23px;
	top: 11px;
}

.login-background .qty-container {
	display: inline-block;
	/* align-items: center; */
	/* justify-content: start; */
}

.login-background .qty-container .input-qty {
	text-align: center;
	padding: 6px 10px;
	border: 1px solid #d4d4d4;
	width: 158px;
}

.login-background .qty-container .qty-btn-minus,
.qty-container .qty-btn-plus {
	border: 1px solid #d4d4d4;
	padding: 10px 13px;
	font-size: 10px;
	height: 38px;
	width: 38px;
	transition: 0.3s;
}

.login-background .qty-container .qty-btn-plus {
	margin-left: 8px;
	line-height: 8px;
}

.login-background .qty-container .qty-btn-minus {
	margin-right: 8px;
	line-height: 8px;
}

.login-background .btn-cornered,
.input-cornered {
	border-radius: 4px;
}

.login-background .btn-rounded {
	border-radius: 50%;
}

.login-background .input-rounded {
	border-radius: 50px;
}


.log-container {
	width: 863px;
	margin: auto;
}

.login-background .form-control {
	display: block;
	width: 100%;
	height: 55px;
	padding: 6px 12px;
	font-size: 14px;
	line-height: 1.42857143;
	/* color: #555;
	background-color: #fff;
	background-image: none;
	border: 1px solid #004bff;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
	-webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
	-o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; */
}

.login-background .input-group-addon {
	padding: 6px 16px;
	font-size: 14px;
	font-weight: 400;
	line-height: 1;
	color: #555;
	text-align: center;
	background-color: #eee;
	/* border: 1px solid #004bff; */
	border-right: none;
	border-radius: none;
}

.login-background .panel-info {
	border-color: #002367;
}

.login-background .panel-body {
	padding: 25px;
}

.login-background .btn {
	display: inline-block;
	padding: 12px 30px;

}

.login-background .btn-success {
	color: #fff;
	background-color: #004bff;
	border-color: #004bff;
}

.panel-body h4 {
	font-size: 16px;
	margin: 10px 0 20px;
	padding: 0 !important;
}

.panel-body .form-control {
	margin-bottom: 0;
}

.panel-group .panel-heading+.panel-collapse .panel-body,
.panel-default {
	border: 0 solid #FFFFFF !important;
	box-shadow: 0 0 #FFFFFF;
	background: none;
}


.dropdown-menu1 ul li{
	list-style: none;
  }
  

  
  .menu-trigger img{
	overflow: hidden;
	cursor: pointer;
  }
  
  .dropdown-menu1{
	position: absolute;
	top: 60px;
	right: 10px;
	z-index: -5;
	background-color: #fff;
	border-radius: var(--border-radius);
	box-shadow: -1px 0px 8px 0 rgb(0 0 0 / 5%), 0 6px 20px 0 rgb(0 0 0 / 5%);
	border: 1px solid #d5d5d5;
	padding-bottom: 10px;
	padding-top: 10px;
	width: 200px;
  }
  
  .dropdown-menu1::before{
	content: '';
	position: absolute;
	top: -5px;
	right: 20px;
	height: 20px;
	width: 20px;
	background: var(--secondary-bg);
	transform: rotate(45deg);
  }
  
  .dropdown-menu1.block{
	opacity: 5;
	visibility: visible;
	transform: translateY(0);
	transition: var(--speed) ease;
  }
  
  .dropdown-menu1.none{
	opacity: 0;
	visibility: hidden;
	transform: translateY(-20px);
	transition: var(--speed) ease;
  }
  
  
 .dropdown-menu1 h3{
	width: 100%;
	text-align: center;
	font-size: 18px;
	padding: 20px 0;
	font-weight: 500;
	font-size: 18px;
	color: rgb(0 0 0 / 75%);
	line-height: 1.2rem;
  }
  
.dropdown-menu1  h3 span{
	font-size: 14px;
	color: rgb(0 0 0 / 75%);
	font-weight: 400;
  }
  .dropdown-menu1 ul{
	margin: 0;
  }
  .dropdown-menu1 ul li{
	padding: 0px  0;
	border-top: 1px solid rgba(0, 0, 0, 0.05);
	color: #000;
  }
  
  .dropdown-menu1.dropdownItem ul li:hover Link{
	color: rgb(212, 33, 9);
	cursor: pointer;
  }

  .dropdown-menu1.dropdown-item{
	padding: 20px;
  }
  
  .dropdown-menu1.dropdownItem ul li:hover img{
	opacity: 1;
	cursor: pointer;
  }
  
  .dropdown-menu1.dropdownItem{
	display: flex;
	margin: 1px ;
  }
  
.dropdown-menu1.dropdownItem.img_profile{
	max-width: 20px;
    margin-right: 10px;
	opacity: 0.5;
	transition: var(--speed);
  }
  
.dropdown-menu1 Link{
	max-width: 100px;
	margin-left: 1px;
	transition: var(--speed);
  }

   .ant-drawer-header {
     background-color: #FEFCFC !important;
	 height: 1;
	 opacity: 0,01;
  }

  .ant-drawer-close {
    position: absolute;
    
    right: 0;
  } 

  .ant-drawer-title {
    font-size: 16;
  } 
  

  /*Response site */

  #header {
	position: fixed;
	width: 100%;
	z-index: 1500;
	
  }

  .revenueDetails .show-details{
	font-size: 18px;
    color: black;
  }

  .chartlinks .nomberVoucher{
	
	padding-top: 10px;
  }
  @media screen and (max-width: 750px) {
     
	 .searchInput{
		display: none;
	 }

	 .card-input{
		display: none;
	 }

	 .card-name {
		height: 100%;
		background-color: #F8F8F8;
		width: 40px;
		border-radius: 11px 0px 0px 11px;
	}

	.card-name .arrow {
		position: absolute;
		top: 2px;
		right: 9px;
		cursor: pointer;
		color: #9da1a3;
	}
	.card-name .menu-mobile {
		font-size: 20px;
	}

	.searchBox {
		border: 1px solid #9F9F9F;
		border-radius: 5px;
		height: 35px;
	}

	.card-name ul.card-list {
		max-height: 700px;
		font-size: 14px;
		overflow: auto;
		list-style: none;
		padding: 0;
		margin: 0;
		position: absolute;
		width: 100%;
		display: none;
		background: #ffffff;
		box-shadow: -1px 0px 8px 0 rgb(0 0 0 / 5%), 0 6px 20px 0 rgb(0 0 0 / 5%);
		border-bottom-left-radius: 3px;
		border-bottom-right-radius: 3px;
		top: 56px;
		left: 0px;
		right: 100px;
		border: 1px solid #d5d5d5;
		width: 250px;
	}
	
  }

  .iconMenu{
	color:#1351F5;
	font-size: 25px;
	padding-left: 10px;
	padding-right: 5px;
  }

  @media screen and (max-width: 480px) {
	.NameUser {
	  display: none;
	}
	.logo img {
		width: 100px;
		height: 35px;
		cursor: pointer;
	}

  }