.darkGray {
    color: #6c6c6c !important;
}

.bgDashboard {
    background-color: #6E7968;
}

.bgDoctor {
    background-color: #ED9494;
}

.bgDepartment {
    background-color: #EDA494;
}

.frontOffice {
    background-color: #3AEBA1;
}

.bgOpd {
    background-color: #5667FF;
}

.bgLiveMeeting {
    background-color: #1D3E0E;
}

.bgPatient {
    background-color: #ED94C4;
}

.bgSchedule {
    background-color: #C194ED;
}

.bgAppointment {
    background-color: #94A2ED;
}

.bgHuman {
    background-color: #94B8ED;
}

.bgFinancial {
    background-color: #94D2ED;
}

.bgPrescription {
    background-color: #94E8ED;
}

.bgLab {
    background-color: #94E8ED;
}

.bgMedicine {
    background-color: #8ED000;
}

.bgPharmacy {
    background-color: #A8AA32;
}

.bgDonor {
    background-color: #6E7968;
}

.bgBed {
    background-color: #EDB994;
}

.bgFile {
    background-color: #E9A31D;
}

.bgFilepage {
    background-color: #94EDC8;
}

.bgReport {
    background-color: #94EDC8;
}

.bgNotice {
    background-color: #CE4B7A;
}

.bgEmail {
    background-color: #543EDC;
}


.bgSMS {
    background-color: #35B541;
}

.bgPayroll {
    background-color: #A1326E;
}

.bgAttendance {
    background-color: #226D98;
}

.bgLeave {
    background-color: #9D629B;
}

.bgChat {
    background-color: #9B8A5F;
}

.bgSettings {
    background-color: #72A603;
}

.bgLogs {
    background-color: #A35777;
}

.bgProfile {
    background-color: #2B6279;
}

.bgSubscription {
    background-color: #900A93;
}

.doctorCard div:nth-child(1) {
    background-color: #FDF6E8;
}

.doctor::after {
    background-color: #FFC94D;
}

.patientCard div:nth-child(1) {
    background-color: #F5F5F5;
}

.patient::after {
    background-color: #7D7DA4;
}

.historyCard div:nth-child(1) {
    background-color: #EAF0FF;
}

.history::after {
    background-color: #5988FF;
}

.checkupCard div:nth-child(1) {
    background-color: #F0EEFF;
}

.checkup::after {
    background-color: #806FFF;
}

.preCard div:nth-child(1) {
    background-color: #FDF2F4;
}

.pre::after {
    background-color: #FF768F;
}

.labsCard div:nth-child(1) {
    background-color: #E9F8FF;
}

.labs::after {
    background-color: #42C3FF;
}

.documentCard div:nth-child(1) {
    background-color: #F9E9FF;
}

.document::after {
    background-color: #D86AFF;
}

.paymentCard div:nth-child(1) {
    background-color: #DBFFCA;
}

.payment::after {
    background-color: #63EB23;
}

.frontOfficeCard div:nth-child(1) {
    background-color: #F2FDF8;
}

.frontOffice::after {
    background-color: #3AEBA1;
}

.opdInCard div:nth-child(1) {
    background-color: #E4E7FF;
}

.opdIn::after {
    background-color: #5667FF;
}

.opdOutCard div:nth-child(1) {
    background-color: #FFECEC;
}

.opdOut::after {
    background-color: #FF8989;
}

.liveCard div:nth-child(1) {
    background-color: #CDCDCD;
}

.live::after {
    background-color: #1D3E0E;
}

.bedCard div:nth-child(1) {
    background-color: #F2F6FD;
}

.bed::after {
    background-color: #6899FB;
}

.deathCard div:nth-child(1) {
    background-color: #FFECE8;
}

.death::after {
    background-color: #FF7878;
}