.amsify-selection-area
.amsify-selection-label-default {
	cursor: pointer;
    border: 1px solid #cccccc;
    min-height: 20px;
    padding: 8px 5px;
}

.amsify-selection-area
.amsify-selection-label-material {
	cursor: pointer;
    border: 1px solid #cccccc;
    min-height: 36px;
    padding: 5px 5px;
}

.amsify-selection-area
.amsify-selection-label
.amsify-label {
	float: left;
	padding: 0px 4px;
}

.amsify-selection-area
.amsify-selection-label {
	cursor: pointer;
}

.amsify-toggle-selection {
	float: right;
	cursor: pointer;
}

.amsify-selection-area .amsify-selection-list {
	display: none;
	position: absolute;
	background: white;
	border: 1px solid #dedede;
	z-index: 1;
}

.amsify-selection-area
.amsify-selection-list
ul.amsify-list {
	list-style: none;
	padding: 3px 0px;
	max-height: 150px;
    overflow-y: auto;
}

.amsify-selection-area
.amsify-selection-list
ul.amsify-list
li.amsify-list-item {
	text-align: left;
    cursor: pointer;
    padding: 0px 10px;	
}

.amsify-selection-area
.amsify-selection-list
ul.amsify-list
li.amsify-list-group {
	text-align: left;
    padding: 0px 10px;
    font-weight: bold;
}

.amsify-selection-area
.amsify-selection-list
ul.amsify-list
li.amsify-item-pad {
    padding-left: 30px;
}

.amsify-selection-area
.amsify-selection-list
ul.amsify-list
li.amsify-item-noresult {
    display: none;
    color: #ff6060;
    font-weight: bold;
    text-align: center;
}

.amsify-selection-area
.amsify-selection-list
ul.amsify-list
li.amsify-list-item:hover {
	background: #bbbbbb;
}

.amsify-selection-area
.amsify-selection-list
ul.amsify-list
li.amsify-list-item:active {
	background: #717171;
    color: white;
    -moz-box-shadow:    inset 0 0 10px #000000;
    -webkit-box-shadow: inset 0 0 10px #000000;
    box-shadow:         inset 0 0 10px #000000;
}

.amsify-selection-area
.amsify-selection-list
.amsify-select-input {
	display: none;
}

.amsify-selection-area
.amsify-selection-list
ul.amsify-list
li.active {
	background: #bbbbbb;
	font-weight: bold;
}

.amsify-selection-area
.amsify-selection-list
ul.amsify-list
li.amsify-item-pad.active {
	font-weight: normal;
}

.amsify-selection-area
.amsify-selection-list
.amsify-select-search-area {
	padding: 3px 5px;
    border: 1px solid #dedede;
    text-align: center;
}

.amsify-selection-area
.amsify-selection-list
.amsify-select-search-area
.amsify-selection-search {
	width: 98%;
    padding: 3px 7px;
    border: 1px solid #d4d4d4;
}

.amsify-selection-area
.amsify-selection-list
.amsify-select-operations {
	padding: 3px 5px;
    border: 1px solid #dedede;
    text-align: right;
}

.amsify-select-clear,
.amsify-select-close {
	margin: 0px 4px;
}